import './App.css';
import {useState} from "react";
import {Button, Card, DatePicker, Form, Input, Modal, Table} from "antd";
import {EmailEngine} from "./engines/email-generator";

function App() {
  const [emailList, setEmailList] = useState([]);
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
  };

  const onEmailDelete = (index) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this email?',
            content: 'This action cannot be undone.',
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => {
                const emails = [...emailList];
                emails.splice(index, 1);
                setEmailList(emails);
            }
        })
  }

  const columns = [
    {
        title: 'From',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
    },
    {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (date) => {
            return date.toLocaleString();
        }
    },
      {
          title: 'Has Attachment',
          dataIndex: 'attachment',
          key: 'attachment',
          render: (attachment) => {
                return attachment ? 'Yes' : 'No';
          }
      },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
            const index = emailList.indexOf(record);
            return (
                <Button onClick={() => onEmailDelete(index)} type='primary' danger size='small'>
                    Delete
                </Button>
            )
        }
    }
  ]

  const onAddEmail = async (values) => {
      let finalAttachment = null;
      if (selectedFile) {
          const addFile = new Promise((resolve, reject) => {
              const reader = new FileReader();

              reader.onload = function(e) {
                  // Remove data URL prefix
                  const base64File = e.target.result.split(',')[1];
                  resolve(base64File);
              };

              reader.readAsDataURL(selectedFile);
          });
          await addFile.then((base64File) => {
            finalAttachment = base64File;
          })
      }
      // Make Date JS Object
      const date = new Date(values.date);

      const emails = [...emailList, {
            email: values.from,
            to: values.to,
            subject: values.subject,
            date: date,
            message: values.message,
            attachment: finalAttachment
      }];
      setEmailList(emails);
      // Reset Form
      form.resetFields();
      // Reset Input
      document.getElementById('attachement-input').value = null;
  }

  const onDownloadEmails = () => {
      const emails = emailList.map((email) => {
          return EmailEngine.createMboxEmail(email.email, email.to, email.subject, email.date, email.message, email.attachment);
      });

      const mboxData = emails.join('');
      // Create a Blob from the data
      const blob = new Blob([mboxData], { type: 'text/plain' });

      // Create a link and trigger a download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'emails.mbox'; // filename to download
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
  }

  return (
      <Card className="App" title='Mail Generator' extra={
          <Button disabled={emailList.length === 0} onClick={() => onDownloadEmails()} type='primary'>
              Generate Backup
          </Button>
      }>
          <div>
              <Table columns={columns} dataSource={emailList} />

              <Form form={form} style={{marginTop: '20px'}} layout='vertical' onFinish={onAddEmail}>
                  <Form.Item name='from' label='From' rules={
                      [
                          {
                              type: 'email',
                              message: 'Please enter a valid email address'
                          },
                          {
                              required: true,
                              message: 'Please enter an email address'
                          }
                      ]
                  }>
                      <Input placeholder='email@x.com' />
                  </Form.Item>
                  <Form.Item name='to' label='To' rules={
                      [
                          {
                              type: 'email',
                              message: 'Please enter a valid email address'
                          },
                          {
                              required: true,
                              message: 'Please enter an email address'
                          }
                      ]
                  }>
                      <Input placeholder='email@x.com' />
                  </Form.Item>
                  <Form.Item name='date' label='Date' rules={
                      [
                          {
                              required: true,
                              message: 'Please enter a date'
                          }
                      ]
                  }>
                      <DatePicker style={{width: '100%'}} showTime />
                  </Form.Item>
                  <Form.Item name='subject' label='Subject' rules={
                      [
                          {
                              required: true,
                              message: 'Please enter a subject'
                          }
                      ]
                  }>
                      <Input placeholder='Hello World' />
                  </Form.Item>
                  <Form.Item name='message' label='Message' rules={
                      [
                          {
                              required: true,
                              message: 'Please enter a message'
                          }
                      ]
                  }>
                      <Input.TextArea rows={10} />
                  </Form.Item>
                  <Form.Item label='Attachement'>
                      <input id='attachement-input' onChange={handleFileChange} type='file' />
                  </Form.Item>

                  <Button htmlType='submit' type='primary' shape='round' block size='large'>
                      Add Email
                  </Button>
              </Form>
          </div>
      </Card>
  );
}

export default App;
