const formatEmailDate = (date) => {
    return date.toUTCString();
}

const createMboxEmail = (from, to, subject, date, body, attachment) => {
    let boundary = "----=_NextPart_000_0000"; // Define a MIME boundary
    let mboxFromLine = `From ${from.replace(/ /g, "_")} ${formatEmailDate(date)}\n`;
    let emailHeaders = `From: ${from}\nTo: ${to}\nSubject: ${subject}\nDate: ${formatEmailDate(date)}\n\n`;

    // Email body
    let emailBody = `${body}\n\n`;

    if (attachment) {
        mboxFromLine = `From ${from.replace(/ /g, "_")} ${formatEmailDate(date)}\n`;
        emailHeaders = `From: ${from}\nTo: ${to}\nSubject: ${subject}\nDate: ${formatEmailDate(date)}\nMIME-Version: 1.0\nContent-Type: multipart/mixed; boundary="${boundary}"\n\n`;
        emailBody = `--${boundary}\nContent-Type: text/plain\n\n${body}\n\n`;
        // Attachment
        emailBody += `--${boundary}\nContent-Type: application/octet-stream; name="attachment.txt"\nContent-Transfer-Encoding: base64\nContent-Disposition: attachment; filename="attachment.txt"\n\n${attachment}\n\n--${boundary}--`;
    }

    return mboxFromLine + emailHeaders + emailBody;
}

export const EmailEngine = {
    createMboxEmail
}
